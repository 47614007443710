.result-container {
	/* background-color: #2A53D4; */
	background-size: cover;
	display: flex;

	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;

	height: 100%;
}

.result-container-header {
	color: white;
	width: 100%;
	padding: 0 24px;
	margin-top: 5%;
	font-size: 18px;
	font-weight: bold;
}

.result-details-card {
	background-color: white;
	padding: 24px;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: -webkit-fill-available;

	border-top-left-radius: 30px;
	border-top-right-radius: 30px;
	position: sticky;
	bottom: 0;
}

#details-card-heading {
	font-size: 15px;
}

.details-card ul li {
	margin-top: 5%;
	font-size: 12px;
}

.details-card ul {
	/* padding-left: 8%;
  padding-right: 4%; */
}

.read-results {
	width: 100%;
	background-color: #f4f0f8;
	opacity: 1;
	border-radius: 8px;
	padding: 2%;
	font-size: 10px;
}

#takeAssessmentCta {
	background-color: #009ae0;
	color: white;
	width: 100%;
	font-weight: bold;
	border: none;
	height: 40px;
	border-radius: 5px;
	margin-top: 2%;
}

#result-container-bottom-text {
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 24px;
	/* or 150% */

	color: #666666;
}

.result-container-bottom {
	text-align: center;
	margin-top: 60px;
	margin-bottom: 12px;
	width: 100%;
}

.result-head-text {
	color: white;
	font-size: 16px;
	padding: 0 24px;
	margin-top: 0px;
	text-align: justify;
}

.result-details-card-head {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 5%;
}

#result-p1 {
	font-size: 14px;
	font-weight: bold;
}

#result-p2 {
	font-size: 12px;
	color: #009ae0;
	font-weight: 600;
}

.sliderText {
	font-size: 10px;
	margin-top: 10%;
	color: #666666;
}

.result-slider {
	-webkit-appearance: none;
	width: 100%;
	height: 10px;
	border-radius: 5px;
	background: #e5e5e5;
	outline: none;

	/* opacity: 0.7; */
	-webkit-transition: 0.2s;
	transition: opacity 0.2s;
	box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.15);
}

.result-slider::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	width: 25px;
	height: 25px;
	border-radius: 50%;
	border: 3px solid white;

	background: #03256c;
	cursor: pointer;
	filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.25));
}

.result-slider::-moz-range-thumb {
	width: 25px;
	height: 25px;
	border-radius: 50%;

	background: #03256c;
	cursor: pointer;
}

.result-slider-values {
	margin-left: 2%;
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
}

.result-slider-value-span {
	font-size: 14px;
	color: #777777;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

#result-slider-value {
	font-weight: bold;
	margin-top: 5%;
	font-size: 20px;
}

.result-details-card-text {
	font-size: 16px;
	width: 100%;
	/* text-align: justify; */
	color: #666666;
	/* margin-top: 10%; */
	margin-top: 16px;
}

.Difference {
	position: absolute;
	top: 0;
	left: 0;

	width: 100vw;
	height: 100vh;

	background-color: rgba(0, 0, 0, 0.6);

	display: flex;
	align-items: flex-end;
}

.Difference__Modal {
	background-color: white;

	width: 100%;

	border-radius: 16px 16px 0 0;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;

	padding: 32px;

	max-height: 90vh;
	overflow-y: auto;
}

.Difference__Modal::-webkit-scrollbar {
	display: none;
}

.Difference__Modal__Top {
	position: relative;
	width: 100%;
	display: flex;
	justify-content: flex-end;
}
.Difference__Modal__Top--Sourav {
	/* margin-top: -24px; */

	margin: -24px auto 12px;
}
.Difference__Modal__Top--Close {
	position: fixed;
	/* top: 0;
  right: 0; */
}

.Difference__Modal__Body {
}

.Difference__Modal__Body__Text {
	/* margin-bottom: 32px;
	padding-bottom: 32px; */
}

.Difference__Modal__Body__Text--Heading {
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 24px;
	/* identical to box height, or 120% */

	color: #2d2d45;

	margin-bottom: 16px;
}

.Difference__Modal__Body__Text--SubHeading {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 21px;
	/* or 150% */

	color: #2d2d45;
}

.Difference__Modal__Body__Input {
	margin-bottom: 24px;
}

.Difference__Modal__Body__Input--Text {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 21px;
	/* or 150% */

	color: #2d2d45;

	margin-bottom: 8px;
}

.Difference__Modal__Body__Input__Container {
	display: flex;
	border-bottom: 1px solid #e5e5e5;

	padding: 6px 0;
}

.Difference__Modal__Body__Input__Container--CountryCode {
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 20px;

	color: #333333;
}

.Difference__Modal__Body__Input__Container--Field {
	border: none;
	outline: none;

	width: 100%;
	margin-left: 8px;

	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 20px;

	color: #333333;
}

.Difference__Modal__Body__Input__Container--Field::placeholder {
	color: #a3a3a3;
}

.Difference__Modal__Body__Bottom {
	/* position: fixed; */
	bottom: 0;
	left: 0;

	width: 100%;
	background-color: white;

	/* padding: 24px 32px 0px; */
}

.Difference__Modal__Body__Bottom--Button {
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 20px;
	text-align: center;

	color: #ffffff;

	background: #009ae0;
	box-shadow: 0px 2px 4px rgba(0, 154, 224, 0.24);
	border-radius: 4px;

	padding: 12px;

	width: 100%;
}

.LoadingScreen {
	height: 70vh;
	display: flex;
	flex-direction: column;

	justify-content: center;
	align-items: center;

	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	/* or 150% */

	text-align: center;

	color: #ffffff;
}
.loader {
	border: 2px solid #999999;
	border-radius: 50%;
	border-top: 3px solid rgba(0, 0, 0, 0.87);
	width: 40px;
	height: 40px;
	-webkit-animation: spin 1s ease-in-out infinite; /* Safari */
	animation: spin 1s ease-in-out infinite;

	margin-bottom: 24px;
}

.ThankYou {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;

	background-color: rgba(0, 0, 0, 0.6);

	display: grid;
	place-content: center;
	padding: 14px;
}
.ThankYou__Content {
	width: 100%;
	background-color: white;

	padding: 16px;

	border-radius: 6px;
}
.ThankYou__Content__Top {
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	/* identical to box height, or 150% */

	color: #2d2d45;

	margin-bottom: 16px;
}
.ThankYou__Content__Body {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 21px;
	/* or 150% */

	color: #2d2d45;
	margin-bottom: 32px;
}
.ThankYou__Content__Button {
	background: #009ae0;
	box-shadow: 0px 2px 4px rgba(0, 154, 224, 0.24);
	border-radius: 4px;

	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 20px;
	text-align: center;

	color: #ffffff;

	padding: 12px;

	width: 100%;
}
/* Safari */
@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.inputContainer {
	position: relative;
	width: 100%;

	padding: 3px;

	display: flex;
	align-items: center;

	margin-top: 16px;
}

.FillerBar {
	position: absolute;
	left: 0;
	width: 100%;
	height: 100%;

	padding: 3px;
}

.Filler {
	background: linear-gradient(
		155.59deg,
		#03256c 63.78%,
		#1a389c 80.63%,
		#2541b2 89.91%
	);
	border-top-left-radius: 8px;
	border-bottom-left-radius: 8px;

	height: 10px;

	width: 100%;
}
