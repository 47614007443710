.card-container {
	position: relative;

	width: 90%;
	/* height: 100px; */
	padding: 5%;
	margin: 5%;
	background-color: white;
	border-radius: 5px;
	display: flex;

	flex-direction: column;
	justify-content: center;

	-webkit-animation-duration: 0.3s;
	animation-duration: 0.3s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;

	-webkit-animation-name: customAnimation;
	animation-name: customAnimation;
}

.question {
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	/* or 150% */

	color: #2d2d45;

	margin-bottom: 16px;
}

.answer-btn-group {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

/* .answer-btn {
	font-size: 10px;
	width: 95%;
	height: 30px;
	margin-top: 3%;
	background-color: #f9f9f9;
	border-radius: 5px;
	border: 0.5px solid #e5e5e5;
} */

.selected-option {
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	/* identical to box height, or 150% */
	height: 100%;

	text-align: center;

	width: 100%;
	/* height: 30px; */
	padding: 12px;
	margin-top: 3%;
	background-color: #ffffff;
	color: #009ae0;
	border-radius: 5px;
	border: 2px solid #009ae0;
}

.unselected-option {
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	/* identical to box height, or 150% */

	text-align: center;

	color: #2d2d45;

	height: 100%;
	width: 100%;
	/* height: 30px; */
	padding: 12px;
	margin-top: 3%;
	background-color: #f9f9f9;
	border-radius: 5px;
	border: 0.5px solid #e5e5e5;
}

.MultpleNote {
	font-style: normal;
	font-weight: bold;
	font-size: 12px;
	line-height: 16px;
	/* identical to box height, or 133% */

	text-align: left;

	color: #666666;

	/* margin-top: 12px; */
}

.WhiteLine {
	position: absolute;
	top: -24px;
	border-left: 1px dashed #ffffff;
	border-right: 1px dashed #ffffff;

	min-height: 24px;
	width: 90%;
}
@keyframes customAnimation {
	0% {
		transform: translateY(10vh);
	}
	100% {
		transform: translateY(0);
	}
}
