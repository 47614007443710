.Instruction-container {
	background-size: cover;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	height: 100%;
}

.Instruction-container-header {
	padding: 0 24px;
	color: white;
	width: 100%;
	/* padding-left: 3%; */
	margin: 5% 0 0;
	text-align: start;
	font-size: 22px;

	display: flex;
	justify-content: space-between;
	align-items: center;
}

.Instruction-container-header > img {
	width: 140px;
}

#instruction-back-btn {
	align-self: flex-start;
	padding: 16px 24px;
}

.details-card {
	background-color: white;
	padding: 24px;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	margin-top: -8px;
	border-top-left-radius: 30px;
	border-top-right-radius: 30px;

	height: 100%;
	position: sticky;
	bottom: 0;
}

#details-card-heading {
	font-size: 15px;
	margin-top: 5%;
}

.details-card ul li {
	margin-top: 5%;
	font-size: 14px !important;
	color: #7c7c82;
}

.details-card ul {
	/* padding-left: 8%; */
	/* padding-right: 4%; */
	margin-top: 0%;
}

.read-instructions {
	width: 100%;
	background-color: #f4f0f8;
	opacity: 1;
	border-radius: 8px;
	padding: 2%;
	margin-top: 4%;
	font-size: 12px;
}

#takeAssessmentCta {
	background-color: #009ae0;
	color: white;
	width: 90%;
	font-weight: bold;
	border: none;
	height: 40px;
	border-radius: 5px;
	margin-top: 5%;
}

#instruction-container-bottom-text {
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	/* identical to box height, or 133% */

	color: #666666;
}

.instruction-container-bottom {
	padding: 24px 24px 16px;
	/* text-align: center; */
	position: absolute;

	background-color: white;
	bottom: 0;
	width: 100%;
}
