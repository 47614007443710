@font-face {
	src: url(https://fonts.cdnfonts.com/css/circular-std-book);
}

body {
	margin: 0;
	font-family: "Circular Std Book";
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	background-image: url(https://cp-assets-public.s3.ap-south-1.amazonaws.com/classplus-websites/common/images/Background.svg) !important ;
	background-size: cover !important;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
