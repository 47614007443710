.WelcomePage-container {
	padding: 10px 20px 0 20px;
	/* background-color: #2a53d4; */
	background-size: cover;
	display: flex;
	height: 100vh;
	overflow: hidden;
	flex-direction: column;
	align-items: flex-start;
	/* justify-content: center; */
}

#welcome-back-btn {
	margin-bottom: 6%;
	margin-top: 16px;
}

.welcome-logo {
	width: 45%;
}

#welcome-head {
	color: #fcde0b;
	font-size: 30px;
	font-weight: bold;
	margin-top: 20px;
	margin-bottom: 8px;
}

#welcome-text {
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 150%;
	/* or 27px */

	color: #ffffff;
}

.welcomeCTA {
	margin-top: 32px;

	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 150%;

	padding: 12px 40px;
	white-space: nowrap;
	/* identical to box height, or 24px */

	color: #ffffff;

	background: #009ae0;
	border: 1px solid #009ae0;
	box-sizing: border-box;
	border-radius: 136px;
}

#welcome-sg {
	width: 58%;
	align-self: flex-end;
	position: absolute;
	bottom: -60px;
	right: 10px;
	/* transform: scale(2); */
}
