.slider-card-container {
	width: 90%;
	/* height: 100px; */
	padding: 5%;

	background-color: white;
	border-radius: 5px;
	display: flex;
	margin-bottom: 5%;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.slider-question {
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 24px;
	/* or 150% */

	color: #2d2d45;
}
.sliderText {
	font-size: 10px;
	margin-top: 10%;
	color: #666666;
}

.question-slider {
	-webkit-appearance: none;
	width: 100%;
	height: 10px;
	border-radius: 5px;
	background: #e5e5e5;
	outline: none;

	/* height: 5%; */
	/* opacity: 0.7; */
	-webkit-transition: 0.2s;
	transition: opacity 0.2s;
	box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.15);
}

.question-slider::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	width: 25px;
	height: 25px;
	border-radius: 50%;
	border: 3px solid white;

	background: #03256c;
	cursor: pointer;
	filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.25));

	z-index: 100 !important;
	position: relative;
}

.question-slider::-moz-range-thumb {
	width: 25px;
	height: 25px;
	border-radius: 50%;

	background: #03256c;
	cursor: pointer;
}

.question-slider-values {
	padding: 0 1%;
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
}

.question-slider-value-span {
	font-size: 12px;
	color: #777777;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.question-slider-value-span-2 {
	font-size: 12px;
	color: #777777;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
}

.question-slider-value-span-3 {
	font-size: 12px;
	color: #777777;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: flex-end;
}

.slider-value-display {
	font-weight: bold;
	margin-top: 10%;
	font-size: 20px;
	text-align: center;
	margin-bottom: 10px;
}

.inputContainer {
	position: relative;
	width: 100%;

	padding: 3px;

	display: flex;
	align-items: center;

	margin-top: 16px;
}

.FillerBar {
	position: absolute;
	left: 0;
	width: 100%;
	height: 100%;

	padding: 3px;
}

.Filler {
	background: linear-gradient(
		155.59deg,
		#03256c 63.78%,
		#1a389c 80.63%,
		#2541b2 89.91%
	);
	border-top-left-radius: 8px;
	border-bottom-left-radius: 8px;

	height: 10px;

	width: 100%;
}
