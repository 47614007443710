.details-container {
	/* background-color: #2A53D4; */
	background-size: cover;
	display: flex;
	width: 100%;
	height: 100%;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
}

.details-container-header {
	color: white;
	width: 100%;
	padding-left: 24px;
	margin-top: 5%;
	font-size: 22px;
}

#details-back-btn {
	margin-left: 24px;
	margin-top: 3%;
}

.details-details-card {
	background-color: white;
	padding: 24px;

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
	height: 100%;

	overflow-y: auto;
	margin-top: 10%;
	border-top-left-radius: 30px;
	border-top-right-radius: 30px;

	padding-bottom: 150px;
	/* position: sticky;
	bottom: 0; */
}

#details-details-card-heading {
	font-size: 15px;
}

.details-card ul li {
	margin-top: 5%;
	font-size: 12px;
}

.details-card ul {
	padding-left: 24px;
	/* padding-right: 4%; */
}

.details-read-detailss {
	width: 100%;
	background-color: #f4f0f8;
	opacity: 1;
	border-radius: 8px;
	padding: 2%;
	font-size: 10px;
}
.details-error {
	color: red;
	font-size: 12px;
	line-height: 15px;
}
#details-takeAssessmentCta {
	background-color: #009ae0;
	color: white;
	width: 100%;
	font-weight: bold;
	border: none;
	height: 40px;
	border-radius: 5px;
	margin-top: 8px;
}

#details-takeAssessmentCta:disabled {
	background-color: #ededed;
}

#details-container-bottom-text {
	font-size: 12px;
}

.details-container-bottom {
	/* text-align: center; */
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	font-size: 18px;
	padding: 24px;
	background-color: white;
}

.details-input-text {
	/* margin-left: 5%; */
	margin-top: 5%;
	font-size: 14px;
	font-weight: 600;
}

#details-input-name {
	outline: none;
	margin-top: 3%;
	/* margin-left: 5%; */
	width: 100%;
	height: 25px;
	border-top: 0;
	border-left: 0;
	border-right: 0;
	border-bottom: 0.5px solid #e5e5e5;

	font-size: 18px;
}

.details-btn-group-1 {
	margin-top: 5%;
	display: flex;
	flex-direction: row;
	width: 100%;
	align-items: center;
	justify-content: flex-start;
	/* padding-left: 5%; */
	gap: 3%;
}

.details-btn-1 {
	width: 18%;
	height: 40px;
	border-radius: 8px;
	border: 0.1px solid #009ae0;
	color: #009ae0;
	background-color: #ffffff;
}

.details-btn-unselcted {
	width: 18%;
	height: 40px;
	border-radius: 8px;
	border: 0.1px solid #e5e5e5;
	color: black;
}

.details-btn-group-2 {
	margin-top: 5%;
	display: flex;
	flex-direction: row;
	width: 100%;
	align-items: center;
	justify-content: flex-start;
	/* padding-left: 5%; */
	gap: 3%;
}

.details-btn-2 {
	width: 40%;
	height: 40px;
	border-radius: 8px;
	background-color: #ffffff;
	border: 0.1px solid #009ae0;
	color: #009ae0;
}

.details-btn-2-unselected {
	width: 40%;
	height: 40px;
	border-radius: 8px;
	background-color: #e5e5e5;
	border: 0.1px solid #e5e5e5;
}

.type-error {
	margin-bottom: 100%;
}

.Difference__Modal__Body__Input2 {
	margin: 24px 0 0;

	width: 100%;
}

.Difference__Modal__Body__Input--Text2 {
	font-style: normal;
	font-size: 14px !important;
	font-weight: 600 !important;
	line-height: 21px;
	/* or 150% */

	color: #2d2d45;

	margin-bottom: 8px;
}

.Difference__Modal__Body__Input__Container2 {
	display: flex;
	border-bottom: 1px solid #e5e5e5;

	padding: 6px 0;
}

.Difference__Modal__Body__Input__Container--CountryCode2 {
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 20px;

	color: #333333;
}

.Difference__Modal__Body__Input__Container--Field2 {
	border: none;
	outline: none;

	width: 100%;
	margin-left: 8px;

	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 20px;

	color: #333333;
}

.Difference__Modal__Body__Input__Container--Field2::placeholder {
	color: #a3a3a3;
}
