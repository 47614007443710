@font-face {
	src: url(https://fonts.cdnfonts.com/css/circular-std-book);
}


.homepage2-container{
    background-image: url(../../resources/NewHomepages/Homepage2/Background.png) !important;
    width: 100%;
    height: 100vh;
    
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.homepage2-top{
    width: inherit;
    height: 40%;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#homepage2-classplus-logo{
    width: 35%;
    align-self: flex-start;
    margin-top: 5%;
    margin-left: 5%;
}

.homepage2-text-sg{
    display: flex;
    flex-direction: row;
    align-items: center;
   margin-top: 25%;
    width: 100%;

}

.homepage2-top-text{
    display: flex;
    flex-direction: column;
    text-align: left;
    background-color: #E1E9FF;
    width: 55%;
    padding: 10px 15px 10px 15px;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    /* font-family: "Circular Std Book"; */
}

#homepage2-SG{
    width: 60%;
    right: 1%;
    position: absolute;
    bottom: 0;

}

.homepage2-bottom{
    width: inherit;
    height: 60%;
}

.homepage2-bottom-header{
    width: inherit;
    height: 30%;
    background-color: #21275C;
    display: flex;
    align-items: center;
    justify-content: center;
    /* font-family: "Circular Std Book"; */

}

.homepage2-bottom-header h1{
    color: white;
    width: 90%;
    font-size: 32px;
}

.homepage2-bottom-content{
    width: inherit;
    height: 70%;
    background-color: #2B3166;
    display: flex;
    flex-direction: column;
    align-items: center;
  
    color: white;
}
.homepage2-bottom-text{
    width: 90%;
    padding-top: 10%;
}

.homepage2-bulletpoints{
    display: flex;
    align-items: center;
    font-size: 18px;
    margin-top: 5%;
    gap: 2%;
    /* font-family: "Circular Std Book"; */
    
}

.homepage2-bullets{
    font-size: 18px;
}

#homepage2-CTA{
    background: #FCDE0B;
    box-shadow: 0px 4px 4px rgba(0, 154, 224, 0.25);
    border-radius: 8px;
color: black;
font-size: 15px;
font-weight: bold;
width: 90%;
height: 50px;
border: none;
margin-bottom: 10px;

display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
padding-left: 35px;

}

.homepage2-CTA-div{
    margin-top: 10%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* font-family: "Circular Std Book"; */
}

#lottie-black{
    width: 35px;
    height: 35px;

    background: transparent;
}