.modal-header {
  margin-top: 3% !important;
}

#modal-content {
  font-size: 14px;
  margin-top: 10%;
}

.modal-bottom {
  width: 100%;
  text-align: center !important;
}

.modal-btn1 {
  border: none;
  background-color: #009ae0;
  color: white;
  height: 35px;
  width: 100%;

  /* margin-top: 5%; */
  font-weight: bold;
  border-radius: 5px;
}

.modal-btn2 {
  color: #009ae0;
  margin-top: 12px;

  width: 100%;
}
