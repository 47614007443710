@font-face {
	src: url(https://fonts.cdnfonts.com/css/circular-std-book);
    
}

.homepage3-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

#homepage3-classpluslogo{
    align-self: flex-start;
    margin-top: 5%;
    margin-left: 5%;
}

#homepage3-meter{
    margin-top: 10%;
    width: 65%;
}

#homepage3-headingText{
    color: #FCDE0B;
    font-size: 40px;
    width: 80%;
    text-align: center;
    /* font-family: "Circular Std Book"; */
}

#homepage3-text{
    color: white;
    font-size: 22px;
    text-align: center;
    width: 85%;
    /* font-family: "Circular Std Book"; */
}

.homepage3-CTA-div{
    width: 100%;
    margin-top: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#homepage3-CTA{

height: 50px;
width: 85%;
color: white;
font-weight: bold;
font-size: 15px;
border: none;
    background: #009AE0;
box-shadow: 0px 4px 4px rgba(0, 154, 224, 0.25);
border-radius: 8px;
/* font-family: "Circular Std Book"; */


display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
padding-left: 50px;
}

#homepahe3-CTA-bottomtext{
    color: white;
    font-size: 14px;
    margin-top: 10px;
    /* font-family: "Circular Std Book"; */
}

#lottie{
    width: 50px;
    height: 80px;

    background: transparent;
}