.questions-container {
	/* background-image: url(../../resources/questionBG.png); */
	background-size: cover;
	width: 100%;
	height: 100vh;
	scroll-behavior: smooth;
	background-repeat: no-repeat;
	overflow-y: scroll;
	display: flex;
	gap: 15px;
	flex-direction: column;
	align-items: center;
}

.questions-container::-webkit-scrollbar {
	display: none;
}

.navBar {
	padding-left: 2%;
	padding-right: 2%;
	margin-bottom: 5%;
	width: 100%;
	height: 60px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	background-color: #03174c;
	position: fixed;

	z-index: 10;
}

.questionCount {
	background-color: #033f5c;
	width: 30%;
	height: 30px;
	color: white;
	font-size: 12px;
	font-weight: bold;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 30px;
}

/* .question4-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
} */

#see-resuts-cta {
	background-color: #009ae0;
	margin: 0 5% 5%;
	color: white;
	width: 90%;
	font-weight: bold;
	border: none;
	height: 40px;
	border-radius: 5px;
}

#see-resuts-cta:disabled {
	background-color: #ededed;
}

.zindex_forward {
	position: absolute;
	bottom: 25px;
	right: 25px;
	background: #009ae0;
	color: white;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	border: none;
	display: grid;
	place-items: center;
}
